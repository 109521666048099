<mat-form-field style="margin: 0 5px;">
  <mat-label>{{ name }}</mat-label>
  <input type="text"
         placeholder="{{ placeholder ?? name }}"
         [attr.aria-label]="name"
         matInput
         [formControl]="formControl"
         [matAutocomplete]="auto">
  <!--         (input)="onInput($event)"-->
  <button *ngIf="value !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete panelWidth="fit-content" autoActiveFirstOption #auto="matAutocomplete">
<!--    (optionSelected)="onSelectionChange($event)"-->
<!--    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option}}</mat-option>-->
    @for (option of filteredOptions | async; track option) {
      <mat-option [value]="option">{{option}}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
