import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
  export class LoaderService {
    private loading = false;
    private counter = 0;

    show() {
        this.loading = true;
        this.counter++;
    }

    hide() {
        this.counter--;
        if (this.counter === 0) {
            this.loading = false;
        }
      this.endComputation();
    }

    isLoading(): boolean {
        return this.loading;
    }

    private computationLoading = false;
    startComputation() {
      this.computationLoading = true;
    }
    endComputation() {
      this.computationLoading = false;
    }
    isComputationLoading(): boolean {
      return this.computationLoading;
    }
  }
