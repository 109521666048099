<h2 mat-dialog-title>{{'Select a Namespace' | translate}}</h2>
<mat-dialog-content class="mat-typography">

<!--  <app-autocomplete-form name="Namespace" [formControl]="this.namespacesService.namespaceCtrl" [options]="(this.namespacesService.organizationUnitsStr$ | async) || []" />-->


<!--  <mat-form-field style="margin: 0 5px; width: 100%">-->
<!--    <mat-label>Filter Namespaces ...</mat-label>-->
<!--&lt;!&ndash;    <input type="text"&ndash;&gt;-->
<!--&lt;!&ndash;           placeholder="Filter Namespaces ..."&ndash;&gt;-->
<!--&lt;!&ndash;           matInput&ndash;&gt;-->
<!--&lt;!&ndash;           aria-label="namespace"&ndash;&gt;-->
<!--&lt;!&ndash;           name="namespace"&ndash;&gt;-->
<!--&lt;!&ndash;           [formControl]="formControl"&ndash;&gt;-->
<!--&lt;!&ndash;           [matAutocomplete]="auto"&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--    &lt;!&ndash;  <button *ngIf="computation.Description !== ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearDescription()">&ndash;&gt;-->
<!--    &lt;!&ndash;    <mat-icon>close</mat-icon>&ndash;&gt;-->
<!--    &lt;!&ndash;  </button>&ndash;&gt;-->
<!--  </mat-form-field>-->


<!--  foreach namespace organizationUnits$-->
<!--    <mat-autocomplete panelWidth="panelWidth" autoActiveFirstOption #auto="matAutocomplete">-->
    <mat-list>
    @for (namespace of options; track namespace) {
      <mat-option (click)="selectOptions(namespace)">
        <span mat-line>{{ namespace.Unit.Name }}</span><br/>
        <small style="color: var(--mdc-list-list-item-supporting-text-color)" class="namespace-id">{{ namespace.Unit.Id }}</small>
        <!--        <p mat-line>{{ namespace.Type }}</p>-->
      </mat-option>
    <hr/>
    }
    </mat-list>

  <!--    </mat-autocomplete>-->
<!--    <mat-list>-->
<!--    @for (namespace of options$ | async; track namespace) {-->
<!--      <mat-option>-->
<!--        <span mat-line>{{ namespace.Unit.Name }}</span><br/>-->
<!--        <small class="namespace-id">{{ namespace.Unit.Id }}</small>-->
<!--&lt;!&ndash;        <p mat-line>{{ namespace.Type }}</p>&ndash;&gt;-->
<!--      </mat-option>-->
<!--    }-->

<!--  </mat-list>-->

</mat-dialog-content>

