import {Component, Input, OnChanges, OnInit, inject} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {Observable, startWith} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'app-autocomplete-form',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgForOf,
    MatIcon,
    MatIconButton,
    NgIf
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, {skipSelf: true}),
    }
  ],
  templateUrl: './autocomplete-form.component.html',
})
export class AutocompleteFormComponent implements OnInit, OnChanges {
  @Input({required: true}) name;
  @Input() options: string[] = [];
  @Input() placeholder: string;
  @Input() form;
  @Input() formControl: FormControl;
  parentContainer = inject(ControlContainer);
  // selectedItem: string;
  filteredOptions: Observable<string[]>;

  get value() {
    return this.formControl.value || '';
  }

  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  ngOnInit() {
    if (this.formControl) {
      this.parentFormGroup.addControl(this.name, this.formControl);
    } else {
      this.formControl = this.form.get(this.name);
      if (!this.formControl) {
        this.formControl = new FormControl('');
      }
    }

    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
    // this.options.subscribe((value) => { this.options = value; });
  }

  ngOnChanges() {
    this.formControl?.setValue(this.formControl.value);
  }

  private _filter(value: string): string[] {
    const filterValue = value?.toLowerCase() ?? '';

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  clearSelection() {
    this.formControl.setValue('');
    // this.selectedItem = '';
  }

  // onInput(event: any) {
  //   this.selectedItem = event.target.value;
  // }
  // onSelectionChange(event: any): void {
  //   this.selectedItem = event.option.value;
  // }
}
